import * as components from './components'
import {PatientData} from "./components/PatientData";

export const template = {
    name: 'Allergic reaction',
    body: {
        body: [
            {
                type: "label",
                data: "PRESENTING PROBLEM:",
                items: [
                    {
                        type: "text",
                        data: 'Allergic reaction',
                        editable: true,
                        source: null
                    },
                ]
            },
            {
                type: "label",
                data: "HISTORY OF THE PRESENT ILLNESS:",
                required: true,
                items: [
                    ...PatientData(),
                    {
                        type: "text",
                        data: 'who reports',
                        editable: true,
                        source: null
                    },
                    {
                        type: "input",
                        placeholder: "duration",
                        data: "",
                    },
                    {
                        type: "text",
                        data: ',swelling with',
                        editable: true,
                        source: null
                    },
                    {
                        type: "radio-block",
                        data: [{checked: false, data: "no"},
                            {checked: false, data: "mild"},
                            {checked: false, data: "moderate"},
                            {checked: false, data: "severe"}]
                    },
                    {
                        type: "text",
                        data: 'pruritis of',
                        editable: true,
                        source: null
                    },
                    {
                        type: "input",
                        placeholder: "location",
                        data: "",
                    },
                    {
                        type: "text",
                        data: '. Reports exposure to',
                        editable: true,
                        source: null
                    },
                    {
                        type: "input",
                        placeholder: "medication, food, allergen",
                        data: "",
                    },
                    {
                        type: "text",
                        data: 'as potential source of allergic reaction. Otherwise:',
                        editable: true,
                        source: null
                    },
                    {
                        type: "checkbox",
                        data: [
                            {checked: false, data: "dyspnea"},
                            {checked: false, data: "throat swelling"},
                            {checked: false, data: "wheezing"},
                        ]
                    },
                    {
                        type: "text",
                        data: '. Has',
                        editable: true,
                        source: null
                    },
                    {
                        type: "radio-block",
                        data: [{checked: false, data: "no"},
                            {checked: false, data: "frequent"}]
                    },
                    {
                        type: "text",
                        data: 'history of prior allergic reactions.',
                        editable: true,
                        source: null
                    },
                ]
            },
            {
                type: "label",
                data: "REVIEW OF SYSTEMS:",
                items: [
                    {
                        type: "group-block",
                        canDelete: true,
                        items: [
                            {
                                type: "text",
                                data: 'General:',
                                editable: true,
                                source: null
                            },
                            {
                                type: "radio-single",
                                data: [{checked: false, data: "fever"}]
                            },
                            {
                                type: "text",
                                data: '. ',
                                editable: true,
                                source: null
                            }
                        ]
                    },
                    {
                        type: "group-block",
                        canDelete: true,
                        items: [
                            {
                                type: "text",
                                data: 'HENT:',
                                editable: true,
                                source: null
                            },
                            {
                                type: "radio-single",
                                data: [{checked: false, data: "congestion"}]
                            },
                            {
                                type: "text",
                                data: '. ',
                                editable: true,
                                source: null
                            }
                        ]
                    },
                    {
                        type: "group-block",
                        canDelete: true,
                        items: [
                            {
                                type: "text",
                                data: 'Respiratory:',
                                editable: true,
                                source: null
                            },
                            {
                                type: "radio-single",
                                data: [{checked: false, data: "cough"}]
                            },
                            {
                                type: "text",
                                data: '. ',
                                editable: true,
                                source: null
                            }
                        ]
                    },
                    {
                        type: "group-block",
                        canDelete: true,
                        items: [
                            {
                                type: "text",
                                data: 'GI:',
                                editable: true,
                                source: null
                            },
                            {
                                type: "radio-single",
                                data: [{checked: false, data: "abdominal pain"}]
                            },
                            {
                                type: "text",
                                data: '. ',
                                editable: true,
                                source: null
                            }
                        ]
                    },
                    {
                        type: "group-block",
                        canDelete: true,
                        items: [
                            {
                                type: "text",
                                data: 'GU:',
                                editable: true,
                                source: null
                            },
                            {
                                type: "radio-single",
                                data: [{checked: false, data: "urinary complaints"}]
                            },
                            {
                                type: "text",
                                data: '. ',
                                editable: true,
                                source: null
                            }
                        ]
                    },
                    {
                        type: "group-block",
                        canDelete: true,
                        items: [
                            {
                                type: "text",
                                data: 'Musculoskeletal:',
                                editable: true,
                                source: null
                            },
                            {
                                type: "radio-single",
                                data: [{checked: false, data: "other aches or pains"}]
                            },
                            {
                                type: "text",
                                data: '. ',
                                editable: true,
                                source: null
                            }
                        ]
                    },
                    {
                        type: "group-block",
                        canDelete: true,
                        items: [
                            {
                                type: "text",
                                data: 'Endocrine:',
                                editable: true,
                                source: null
                            },
                            {
                                type: "radio-single",
                                data: [{checked: false, data: "generalized weakness"}]
                            },
                            {
                                type: "text",
                                data: '. ',
                                editable: true,
                                source: null
                            }
                        ]
                    },
                    {
                        type: "group-block",
                        canDelete: true,
                        items: [
                            {
                                type: "text",
                                data: 'Neurological:',
                                editable: true,
                                source: null
                            },
                            {
                                type: "radio-single",
                                data: [{checked: false, data: "localized weakness"}]
                            },
                            {
                                type: "text",
                                data: '. ',
                                editable: true,
                                source: null
                            }
                        ]
                    },
                    {
                        type: "group-block",
                        canDelete: true,
                        items: [
                            {
                                type: "text",
                                data: 'Psychiatric:',
                                editable: true,
                                source: null
                            },
                            {
                                type: "radio-single",
                                data: [{checked: false, data: "emotional stress"}]
                            },
                            {
                                type: "text",
                                data: '. ',
                                editable: true,
                                source: null
                            }
                        ]
                    },
                ]
            },
            components.PMH(),
            components.GYM(),
            components.FamSocHistory(),
            components.Medications(),
            components.Allergies(),
            components.PhysicianExamination({
                items: [
                    {
                        type: "label",
                        data: "Generalized Appearance:",
                        canDelete: true,
                        items: [
                            {
                                type: "input",
                                placeholder: "",
                                data: "",
                            },
                            {
                                type: "checkbox",
                                data: [
                                    {checked: false, data: "Alert"},
                                    {checked: false, data: "cooperative"},
                                    {checked: false, data: "in no distress"},
                                ]
                            },
                        ]
                    },
                    components.VitalSigns(),
                    {
                        type: "label",
                        data: "Skin:",
canDelete: true,
                        items: [
                            {
                                type: "input",
                                placeholder: "",
                                data: "",
                            },
                            {
                                type: "text",
                                data: 'Swelling with',
                                editable: true,
                                source: null
                            },
                            {
                                type: "radio-block",
                                data: [{checked: false, data: "no"},
                                    {checked: false, data: "mild"},
                                    {checked: false, data: "moderate"}]
                            },
                            {
                                type: "text",
                                data: 'erythema of',
                                editable: true,
                                source: null
                            },
                            {
                                type: "input",
                                placeholder: "location",
                                data: "",
                            },
                            {
                                type: "text",
                                data: ',',
                                editable: true,
                                source: null
                            },

                            {
                                type: "radio-single",
                                data: [{checked: false, data: "suppuration"}]
                            },
                            {
                                type: "text",
                                data: ',',
                                editable: true,
                                source: null
                            },
                            {
                                type: "radio-single",
                                data: [{checked: false, data: "lymphangitis"}]
                            },
                            {
                                type: "text",
                                data: '. No \"target\" lesions. No other rash noted.',
                                editable: true,
                                source: null
                            }
                        ]
                    },
                    components.Eyes(),
                    {
                        type: "label",
                        data: "ENMT:",
canDelete: true,
                        items: [

                            {
                                type: "input",
                                placeholder: "",
                                data: "",
                            },
                            ...components.RenderRadioSingleBLocks(
                                ['oral mucous membrane involvement',
                                    'edema of uvula'
                                ]),
                            {
                                type: "text",
                                data: '. Mucous membranes moist. Airway patent:',
                                editable: true,
                                source: null
                            },
                            ...components.RenderRadioSingleBLocks(
                                ['stridor', 'hoarseness']),
                            {
                                type: "text",
                                data: '.',
                                editable: true,
                                source: null
                            }
                        ]
                    },
                    components.Neck(),
                    components.ChestRespiratory(),
                    components.HeartCardiovascular(),
                    {
                        type: "label",
                        data: "Abdomen/GI:", canDelete: true,
                        items: [
                            {
                                type: "input",
                                placeholder: "",
                                data: "",
                            },
                            {
                                type: "text",
                                data: 'rebound,',
                                editable: true,
                                source: null
                            },
                            {
                                type: "radio-single",
                                data: [{checked: false, data: "palpable pulsatile or other mass"}]
                            },
                            {
                                type: "text",
                                data: ',',
                                editable: true,
                                source: null
                            },

                            {
                                type: "radio-single",
                                data: [{checked: false, data: "CVA tenderness"}]
                            },
                            {
                                type: "text",
                                data: '.',
                                editable: true,
                                source: null
                            },
                        ]
                    },
                    components.Extremities(),
                    {
                        type: "label",
                        data: "Neuro/Psych:",
canDelete: true,
                        items: [
                            {
                                type: "input",
                                placeholder: "",
                                data: "",
                            },
                            {
                                type: "text",
                                data: 'Mental status as above;',
                                editable: true,
                                source: null
                            },
                            {
                                type: "radio-single",
                                data: [{checked: false, data: "focal findings"}]
                            },
                            {
                                type: "text",
                                data: '.', editable: true,
                                source: null
                            },
                        ]
                    }
                ]
            }),
            components.TreatmentPerformed({items: [{
                    type: "text",
                    data: 'Patient remained stable throughout evaluation and treatment. Treated with Solumedrol 125mg IM.',
                    editable: true,
                    source: null
                },]}),
            components.AssessmentPlan([ {
                type: 'blockCheckboxCondition',
                extraType: 'assessmentContent',
                data: {
                    checkbox: [{checked: true, data: '', isHide: true}],
                    hiddenBlock: [
                        {
                            type: "text",
                            data: 'Follow up consultation with',
                            editable: true,
                            source: null
                        },
                        {
                            type: "radio-block",
                            data: [{checked: true, data: "PCP"},
                                {checked: false, data: "Allergist"},
                            ]
                        },
                        {
                            type: "text",
                            data: 'as directed. Go to the nearest ED for increasing difficulty breathing or wheezing.',
                            editable: true,
                            source: null
                        },
                    ]
                }
            }], 'The patient received verbal instructions regarding this condition. Written instructions will be send to the patient following the visit.'),
        ]
    }
}
