import * as components from "./components";
import { PatientData} from "./components/PatientData";
import {GroupBlockReviewOfSystem} from "./components/GroupBlockReviewOfSystems";

export const template = {
    name: 'Influenza (Flu)',
    body: {
        body: [
            {
                type: "label",
                data: "PRESENTING PROBLEM:",
                items: [
                    {
                        type: "text",
                        data: 'Fever, cough and fatigue',
                        editable: true,
                        source: null
                    },
                ]
            },
            {
                type: "label",
                data: "HISTORY OF THE PRESENT ILLNESS:",
                required: true,
                items: [
                    ...PatientData(),
                    {
                        type: "text",
                        data: 'who reports history of cough and fever',
                        editable: true,
                        source: null
                    },
                    {
                        type: "input",
                        placeholder: "duration",
                        data: "",
                    },
                    {
                        type: "text",
                        data: '. Cough is reported productive of',
                        editable: true,
                        source: null
                    },
                    {
                        type: "radio-block",
                        data: [{checked: false, data: "no"},
                            {checked: false, data: "purulent"},
                            {checked: false, data: "copious"}]
                    },
                    {
                        type: "text",
                        data: 'sputum,',
                        editable: true,
                        source: null
                    },
                    ...components.RenderRadioSingleBLocks(['hemoptysis']),
                    {
                        type: "text",
                        data: '. Pt',
                        editable: true,
                        source: null
                    },
                    {
                        type: "radio-block",
                        data: [{checked: false, data: "has"},
                            {checked: false, data: "has not"}]
                    },
                    {
                        type: "text",
                        data: 'received flu vaccine this year. Symptoms',
                        editable: true,
                        source: null
                    },
                    {
                        type: "radio-block",
                        data: [{checked: false, data: "had"},
                            {checked: false, data: "did not have"}]
                    },
                    {
                        type: "text",
                        data: 'rapid onset. Pt',
                        editable: true,
                        source: null
                    },
                    {
                        type: "radio-block",
                        data: [{checked: false, data: "has"},
                            {checked: false, data: "has not"}]
                    },
                    {
                        type: "text",
                        data: 'taken any',
                        editable: true,
                        source: null
                    },
                    {
                        type: "radio-block",
                        data: [{checked: false, data: "Tylenol"},
                            {checked: false, data: "Motrin"},
                            {checked: false, data: "Advil"},
                            {checked: false, data: "Ibuprofen"}]
                    },
                    {
                        type: "text",
                        data: 'for symptoms. Otherwise:',
                        editable: true,
                        source: null
                    },
                    {
                        type: "checkbox",
                        data: [
                            {checked: false, data: "sore throat"},
                            {checked: false, data: "ear pain"},
                            {checked: false, data: "sinus congestion"},
                            {checked: false, data: "fatigue"},
                            {checked: false, data: "chest pain"},
                            {checked: false, data: "dyspnea"}
                        ]
                    },
                    {
                        type: "text",
                        data: '.',
                        editable: true,
                        source: null
                    }
                ]
            },
            {
                type: "label",
                data: "REVIEW OF SYSTEMS:",
                items: [
                    GroupBlockReviewOfSystem({title: 'General:', radio: 'prior fever'}),
                    GroupBlockReviewOfSystem({title: 'HENT:', radio: 'prior congestion'}),
                    GroupBlockReviewOfSystem({title: 'Respiratory:', radio: 'prior cough'}),
                    GroupBlockReviewOfSystem({title: 'Cardiovascular:', radio: 'prior chest pain'}),
                    GroupBlockReviewOfSystem({title: 'GI:', radio: 'abdominal pain'}),
                    GroupBlockReviewOfSystem({title: 'GU:', radio: 'urinary complaints'}),
                    GroupBlockReviewOfSystem({title: 'Musculoskeletal:', radio: 'other aches or pains'}),
                    GroupBlockReviewOfSystem({title: 'Endocrine:', radio: 'generalized weakness'}),
                    GroupBlockReviewOfSystem({title: 'Neurological:', radio: 'localized weakness'}),
                    GroupBlockReviewOfSystem({title: 'Psychiatric:', radio: 'emotional stress'}),
                ]
            },
            components.PMH(),
            components.GYM(),
            components.FamSocHistory(),
            components.Medications(),
            components.Allergies(),
            components.PhysicianExamination({
                items: [
                    components.GeneralizedAppearance(),
                    components.VitalSigns(),
                    {
                        type: "label",
                        data: "Skin:",
canDelete: true,
                        items: [
                            {
                                type: "input",
                                placeholder: "",
                                data: "",
                            },
                            {
                                type: "text",
                                data: 'Warm, dry;',
                                editable: true,
                                source: null
                            },
                            {
                                type: "radio-single",
                                data: [{checked: false, data: "cyanosis"}]
                            },
                            {
                                type: "text",
                                data: '.',
                                editable: true,
                                source: null
                            },
                        ]
                    },
                    components.Eyes(),
                    {
                        type: "label",
                        data: "ENMT:",
canDelete: true,
                        items: [
                            {
                                type: "input",
                                placeholder: "",
                                data: "",
                            },
                            {
                                type: "text",
                                data: 'Mucous membranes moist. Airway patent:',
                                editable: true,
                                source: null
                            },
                            ...components.RenderRadioSingleBLocks(['stridor', 'hoarseness']),
                            {
                                type: "text",
                                data: '. Pharynx',
                                editable: true,
                                source: null
                            },
                            ...components.RenderRadioSingleBLocks(['erythema', 'exudate', 'tonsilar hypertrophy']),
                            {
                                type: "text",
                                data: 'TMs normal B/L.',
                                editable: true,
                                source: null
                            },
                        ]
                    },
                    components.Neck(),
                    {
                        type: "label",
                        data: "Chest/Respiratory:",
canDelete: true,
                        items: [
                            {
                                type: "input",
                                placeholder: "",
                                data: "",
                            },
                            {
                                type: "radio-single",
                                data: [{checked: false, data: "rales"}]
                            },
                            {
                                type: "text",
                                data: ',',
                                editable: true,
                                source: null
                            },
                            {
                                type: "radio-single",
                                data: [{checked: false, data: "rhonchi"}]
                            },
                            {
                                type: "text",
                                data: ',',
                                editable: true,
                                source: null
                            },
                            {
                                type: "radio-single",
                                data: [{checked: false, data: "wheezes"}]
                            },
                            {
                                type: "text",
                                data: ',',
                                editable: true,
                                source: null
                            },
                            {
                                type: "radio-single",
                                data: [{checked: false, data: "rub"}]
                            },
                            {
                                type: "text",
                                data: ';',
                                editable: true,
                                source: null
                            },
                            {
                                type: "text",
                                data: 'breath sounds equal bilaterally.',
                                editable: true,
                                source: null
                            },
                        ]
                    },
                    components.HeartCardiovascular(),
                    {
                        type: "label",
                        data: "Abdomen/GI:", canDelete: true,
                        items: [
                            {
                                type: "input",
                                placeholder: "",
                                data: "",
                            },
                            {
                                type: "text",
                                data: 'Soft;',
                                editable: true,
                                source: null
                            },
                            ...components.RenderRadioSingleBLocks(['tenderness']),
                            {
                                type: "text",
                                data: '.',
                                editable: true,
                                source: null
                            },
                        ]
                    },
                    components.Extremities(components.RenderRadioSingleBLocks(['calf tenderness'], true)),
                    {
                        type: "label",
                        data: "Neuro/Psych:",
canDelete: true,
                        items: [
                            {
                                type: "input",
                                placeholder: "",
                                data: "",
                            },
                            {
                                type: "text",
                                data: 'Mental status as above;',
                                editable: true,
                                source: null
                            },
                            {
                                type: "radio-single",
                                data: [{checked: false, data: "focal findings"}]
                            },
                            {
                                type: "text",
                                data: '.', editable: true,
                                source: null
                            },
                        ]
                    },
                ]
            }),
            components.TestPerformed({items: [
                    {
                        type: 'blockCheckboxCondition',
                        data: {
                            checkbox: [{checked: false, data: 'Covid 19 -'}],
                            hiddenBlock: [
                                {
                                    type: "radio-block",
                                    data: [{checked: true, data: "Positive"}, {checked: false, data: "Negative"},
                                        ]
                                },
                            ]
                        }
                    },
                    {
                        type: 'blockCheckboxCondition',
                        data: {
                            checkbox: [{checked: false, data: 'None'}],
                            hiddenBlock: []
                        }
                    },
                    {
                        type: 'blockCheckboxCondition',
                        data: {
                            checkbox: [{checked: false, data: 'Strep -'}],
                            hiddenBlock: [
                                {
                                    type: "radio-block",
                                    data: [{checked: true, data: "Positive"},{checked: false, data: "Negative"},
                                        ]
                                },
                            ]
                        }
                    },
                    {
                        type: 'blockCheckboxCondition',
                        data: {
                            checkbox: [{checked: false, data: 'Influenza A and B -'}],
                            hiddenBlock: [
                                {
                                    type: "radio-block",
                                    data: [{checked: true, data: "Positive for ___"},
                                        {checked: false, data: "Negative"},
                                        ]
                                },
                            ]
                        }
                    },
                    {
                        type: 'blockCheckboxCondition',
                        data: {
                            checkbox: [{checked: false, data: ''}],
                            hiddenBlock: [
                                {
                                    type: "input",
                                    placeholder: "",
                                    data: "",
                                },
                            ]
                        }
                    },
                ]}),
            components.AssessmentPlan([ {
                type: 'blockCheckboxCondition',
                extraType: 'assessmentContent',
                data: {
                    checkbox: [{checked: true, data: '', isHide: true}],
                    hiddenBlock: [
                        {
                            type: "text",
                            data: 'Follow up to be arranged by',
                            editable: true,
                            source: null
                        },
                        {
                            type: "radio-block",
                            data: [{checked: true, data: "patient"},
                                {checked: false, data: "caretaker"}]

                        },
                        {
                            type: "text",
                            data: 'for increased fever, CP, SOB, unable to tolerate PO fluids or any other conditions with PCP.',
                            editable: true,
                            source: null
                        },
                    ]
                }
            }]),
        ]
    }
}
