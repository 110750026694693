import React from "react";
import "./style.scss";
import {Typography} from "../Typography/Typography";
import {colors} from "../../../assets/colors/colors";
import {validationAddressFields} from "../../../utils/validation";
import {PopupAddressFields} from "../../CreateReqest/StepRequestDataInfo/PopupAddressFields";
import cn from "classnames";
import svg from "../../../dist/icons/addressLoader.svg";

import PlacesAutocomplete, {
    geocodeByAddress,
    getLatLng,
} from "react-places-autocomplete";
import {validationZipCode} from "../../../store/createRequest/actions";

const searchOptions = {
    types: ["address"]
};

export class InputAddress extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            value: "",
            latLng: null,
            isOptions: false,
            results: null,
            loadDataAddress: false,
            zipCode: '',
            isAddressFulfilled: false,
            showPopup: false,
            isAddressOutServedArea: false,
            ignoreNewProps: false,
        };
    }

    handleChange = (address, zip_code) => {
        this.setState({value: address, zipCode: zip_code || '', latLng: '', results: null});
    };

    componentDidMount() {
        if (this.props.value && this.props.value.address_line) {
            this.handleChange(this.props.value.address_line, this.props.value.zip_code);
        } else if (this.props.value && this.props.value.address) {
            this.handleChange(this.props.value.address);
        }
        if (this.props.value?.latLng) {
            this.setState({latLng: this.props.value.latLng})
        }
        if (this.props.initialCheckOutOfServedZone && this.props.value?.address) {
            this.handleSelect(this.props.value.address_line || this.props.value.address);
        }
    }

    componentWillReceiveProps(nextProps, nextContext) {
        if ((nextProps.value && nextProps.value.latLng === null ||
            nextProps.value && !this.state.value) && nextProps.value.address
        ) {
            if (nextProps.value.uuid != null && this.props.ignoreNewProps) {
                if (!this.state.value) {
                    this.handleChange(nextProps?.value?.address_line, nextProps?.value?.zip_code);
                }
                return;
            }
            this.handleChange(nextProps.value.address);
        }
        if (nextProps.value && !this.state.results && nextProps.value.address && !this.props.ignoreNewProps) {
            this.handleSelect(nextProps.value.address);
        }
    }

    handleSelect = address => {
        let zipCode = ''
        this.setState({
            isAddressOutServedArea: false,
            loadDataAddress: true
        });
        let resultSearching = null;
        geocodeByAddress(address)
            .then(results => {
                this.setState({
                    isAddressFulfilled: validationAddressFields(results[0])
                });
                resultSearching = results[0];
                zipCode = results[0].address_components.find(i => i.types.find(j => j === 'postal_code'));
                if(zipCode !== undefined && zipCode['long_name'].length > 5) {
                    zipCode['long_name'] = zipCode['long_name'].substring(0, 5);
                }
                return getLatLng(results[0]);
            })
            .then(async latLng => {
                if (this.props.checkOutOfServedZone && this.props.checkZipcode && zipCode?.long_name && this.state.isAddressFulfilled) {
                    this.state.isAddressOutServedArea = !(await validationZipCode(zipCode?.long_name));
                }
                this.setState({
                    latLng: latLng,
                    value: address,
                    zipCode: zipCode?.long_name || '',
                    results: resultSearching,
                    loadDataAddress: false
                }, this.checkSelectedAddress);
            })
            .catch(error => console.error("Error", error));
    };

    handleClosePopup = () => {
        this.setState({
            showPopup: false
        });
    };

    checkSelectedAddress = async () => {
        let state = {};
        const {results, isAddressFulfilled, value} = this.state;
        const trimValue = value?.trim()
        if (!results && trimValue) {
            this.handleSelect(trimValue);
        } else {
            const country = results?.address_components.find(i => i.types.find(j => j === 'country'))?.short_name;
            const isUSA = country === 'US';
            if (trimValue && (!isAddressFulfilled || !isUSA)) {
                this.setState({
                    showPopup: true
                });
            }
            if (this.state.latLng && ((this.props.checkZipcode && this.state.zipCode) || !this.props.checkZipcode) && isAddressFulfilled) {
                const streetName = results.address_components.find(i => i.types.find(j => j === 'route'))?.long_name;
                const streetNumber = results.address_components.find(i => i.types.find(j => j === 'street_number'))?.long_name;
                this.props.onChange(
                    this.props.field,
                    {
                        address: results?.formatted_address || trimValue,
                        latLng: this.state.latLng,
                        state: results.address_components.find(i => i.types.find(j => j === 'administrative_area_level_1'))?.short_name,
                        street: `${streetNumber} ${streetName}`,
                        city: results.address_components.find(i => i.types.find(j => j === 'locality'))?.long_name
                    },
                    this.state.zipCode,
                    this.state.results, this.state.isAddressOutServedArea
                );
                state.isOptions = false;
            } else {
                this.props.onChange(this.props.field, null);
                state.isOptions = false;
                state.value = "";
            }
            this.setState({...state});
        }
    };

    showOption = () => {
        this.setState({isOptions: true});
    };

    render() {
        let {
            placeholder,
            error,
            className,
            disable
        } = this.props;
        return (
            <>
                <PlacesAutocomplete
                    value={this.state.value}
                    onChange={(...args) => {
                        this.handleChange(...args)
                        this.props.onChange(this.props.field, null, '')
                    }}
                    onSelect={this.handleSelect}
                    searchOptions={searchOptions}
                >
                    {({
                          getInputProps,
                          suggestions,
                          getSuggestionItemProps,
                          loading,
                          ...props
                      }) => {
                        return (
                            <div
                                className={cn("custom-input-container", className, {
                                    "custom-input-container_error": error,
                                    "custom-input-container_disable": disable
                                })}
                                tabIndex="-1"
                            >
                                {this.state.loadDataAddress && (
                                    <div className="custom-input-container_loader">
                                        <img src={svg}/>
                                    </div>
                                )}
                                <input
                                    {...getInputProps({
                                        placeholder: ""
                                    })}
                                    onFocus={this.showOption}
                                    onBlur={e => {
                                        if (this.state.loadDataAddress) e.target.focus();
                                        else this.checkSelectedAddress();
                                    }}
                                    disabled={disable}
                                    className={cn("custom-input-container_input location-search-input", {
                                        'custom-input-container_input_out-served-area': this.state.isAddressOutServedArea
                                    })}
                                />
                                <span
                                    className={cn(
                                        "custom-input-container_placeholder",
                                        this.state.value &&
                                        "custom-input-container_placeholder_isValue"
                                    )}
                                >
                  {placeholder}
                </span>
                                {error && (
                                    <Typography
                                        variant="h5"
                                        text={error}
                                        color={colors.redError}
                                        className="custom-input-container_error"
                                    />
                                )}
                                {this.state.isAddressOutServedArea && (
                                    <Typography
                                        variant="h5"
                                        text='Address is out of working zone'
                                        color={colors.peach}
                                        className="custom-input-container_error"
                                    />
                                )}
                                {this.state.isOptions &&
                                    (loading || (suggestions && !!suggestions.length)) && (
                                        <div className="custom-input-container_dropdown">
                                            {loading && (
                                                <div className="custom-input-container_dropdown_loading">
                                                    Loading...
                                                </div>
                                            )}
                                            {suggestions.map((suggestion, index) => {
                                                const className = suggestion.active
                                                    ? "custom-input-container_dropdown_item active"
                                                    : "custom-input-container_dropdown_item";
                                                return (
                                                    <div
                                                        key={index}
                                                        {...getSuggestionItemProps(suggestion, {
                                                            className
                                                        })}
                                                    >
                                                        {suggestion.description}
                                                    </div>
                                                );
                                            })}
                                        </div>
                                    )}
                            </div>
                        );
                    }}
                </PlacesAutocomplete>
                <PopupAddressFields
                    isOpen={this.state.showPopup}
                    isClose={!this.state.showPopup}
                    handleClose={this.handleClosePopup}
                />
            </>
        );
    }
}
