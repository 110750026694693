import * as components from "./components";
import {PatientData} from "./components/PatientData";
import {GroupBlockReviewOfSystem} from "./components/GroupBlockReviewOfSystems";

export const template = {
    name: 'Cellulitis',
    body: {
        body: [
            {
                type: "label",
                data: "PRESENTING PROBLEM:",
                items: [
                    {
                        type: "text",
                        data: 'Infection (cellulites)',
                        editable: true,
                        source: null
                    },
                ]
            },
            {
                type: "label",
                data: "HISTORY OF THE PRESENT ILLNESS:",
                required: true,
                items: [
                    ...PatientData(),
                    {
                        type: "text",
                        data: 'who complains',
                        editable: true,
                        source: null
                    },
                    {
                        type: "radio-block",
                        data: [{checked: false, data: "mild"},
                            {checked: false, data: "moderate"},
                            {checked: false, data: "severe"}]
                    },
                    {
                        type: "text",
                        data: 'pain, erythema, and tenderness of',
                        editable: true,
                        source: null
                    },

                    {
                        type: "input",
                        placeholder: "location",
                        data: "",
                    },
                    {
                        type: "input",
                        placeholder: "duration",
                        data: "",
                    },
                    {
                        type: "text",
                        data: '. Otherwise:',
                        editable: true,
                        source: null
                    },
                    {
                        type: "checkbox",
                        data: [
                            {checked: false, data: "fever"},
                            {checked: false, data: "trauma"},
                            {checked: false, data: "known foreign body"}
                        ]
                    },
                    {
                        type: "text",
                        data: '.',
                        editable: true,
                        source: null
                    },
                ]
            },
            components.TetanusStatus(),
            {
                type: "label",
                data: "REVIEW OF SYSTEMS:",
                items: [
                    GroupBlockReviewOfSystem({title: 'General:', radio: 'prior fever'}),
                    GroupBlockReviewOfSystem({title: 'HENT:', radio: 'congestion'}),
                    GroupBlockReviewOfSystem({title: 'Respiratory:', radio: 'cough'}),
                    GroupBlockReviewOfSystem({title: 'Cardiovascular:', radio: 'chest pain'}),
                    GroupBlockReviewOfSystem({title: 'GI:', radio: 'abdominal pain'}),
                    GroupBlockReviewOfSystem({title: 'GU:', radio: 'urinary complaints'}),
                    GroupBlockReviewOfSystem({title: 'Musculoskeletal:', radio: 'other aches or pains'}),
                    GroupBlockReviewOfSystem({title: 'Endocrine:', radio: 'generalized weakness'}),
                    GroupBlockReviewOfSystem({title: 'Neurological:', radio: 'localized weakness'}),
                    GroupBlockReviewOfSystem({title: 'Psychiatric:', radio: 'emotional stress'}),
                ]
            },
            components.PMH(),
            components.GYM(),
            components.FamSocHistory(),
            components.Medications(),
            components.Allergies(),
            components.PhysicianExamination({
                items: [
                    components.GeneralizedAppearance(),
                    components.VitalSigns(),
                    {
                        type: "label",
                        data: "Skin:",
canDelete: true,
                        items: [
                            {
                                type: "input",
                                placeholder: "",
                                data: "",
                            },
                            {
                                type: "text",
                                data: 'Warm, dry;',
                                editable: true,
                                source: null
                            },
                            {
                                type: "radio-single",
                                data: [{checked: false, data: "cyanosis"}]
                            },
                            {
                                type: "text",
                                data: '. Mild warmth, swelling, tenderness and erythema of',
                                editable: true,
                                source: null
                            },
                            {
                                type: "input",
                                placeholder: "location",
                                data: "",
                            },
                            {
                                type: "input",
                                placeholder: "size",
                                data: "",
                            },

                            {
                                type: "text",
                                data: '.',
                                editable: true,
                                source: null
                            },
                            ...components.RenderRadioSingleBLocks(
                                ['suppuration', 'fluctuance',
                                    'induration', 'lymphangitis',
                                    'regional lymphadenitis']),
                            {
                                type: "text",
                                data: '.',
                                editable: true,
                                source: null
                            },
                        ]
                    },
                    components.Eyes(components.RenderRadioSingleBLocks(['scleral icterus'], true)),
                    {
                        type: "label",
                        data: "ENMT:",
canDelete: true,
                        items: [
                            {
                                type: "input",
                                placeholder: "",
                                data: "",
                            },
                            {
                                type: "text",
                                data: 'Mucous membranes moist.',
                                editable: true,
                                source: null
                            }
                        ]
                    },
                    {
                        type: "label",
                        data: "Neck:",
canDelete: true,
                        items: [
                            {
                                type: "input",
                                placeholder: "",
                                data: "",
                            },
                            {
                                type: "radio-single",
                                data: [{checked: false, data: "tenderness"}]
                            },
                            {
                                type: "text",
                                data: ',',
                                editable: true,
                                source: null
                            },
                            {
                                type: "radio-single",
                                data: [{checked: false, data: "stiffness"}]
                            },
                            {
                                type: "text",
                                data: '.',
                                editable: true,
                                source: null
                            },
                        ]
                    },
                    {
                        type: "label",
                        data: "Chest/Respiratory:",
canDelete: true,
                        required: true,
                        items: [
                            {
                                type: "input",
                                placeholder: "",
                                data: "",
                            },
                            {
                                type: "radio-single",
                                data: [{checked: false, data: "rales"}]
                            },
                            {
                                type: "text",
                                data: ',',
                                editable: true,
                                source: null
                            },
                            {
                                type: "radio-single",
                                data: [{checked: false, data: "rhonchi"}]
                            },
                            {
                                type: "text",
                                data: ',',
                                editable: true,
                                source: null
                            },
                            {
                                type: "radio-single",
                                data: [{checked: false, data: "wheezes"}]
                            },
                            {
                                type: "text",
                                data: '; breath sounds equal bilaterally.',
                                editable: true,
                                source: null
                            },
                        ]
                    },
                    {
                        type: "label",
                        data: "Heart/Cardiovascular:",
canDelete: true,
                        items: [
                            {
                                type: "input",
                                placeholder: "",
                                data: "",
                            },
                            {
                                type: "text",
                                data: 'Regular;',
                                editable: true,
                                source: null
                            },
                            {
                                type: "radio-single",
                                data: [{checked: false, data: "murmur"}]
                            },
                            {
                                type: "text",
                                data: '; ',
                                editable: true,
                                source: null
                            },
                            {
                                type: "radio-single",
                                data: [{checked: false, data: "gallop"}]
                            },
                            {
                                type: "text",
                                data: '.',
                                editable: true,
                                source: null
                            },
                        ]
                    },
                    {
                        type: "label",
                        data: "Abdomen/GI:", canDelete: true,
                        items: [
                            {
                                type: "input",
                                placeholder: "",
                                data: "",
                            },
                            {
                                type: "text",
                                data: 'Soft;',
                                editable: true,
                                source: null
                            },
                            {
                                type: "radio-single",
                                data: [{checked: false, data: "tenderness"}]
                            },
                            {
                                type: "text",
                                data: '.',
                                editable: true,
                                source: null
                            },
                        ]
                    },
                    {
                        type: "label",
                        data: "Extremities:",
canDelete: true,
                        items: [
                            {
                                type: "input",
                                placeholder: "",
                                data: "",
                            },
                            {
                                type: "text",
                                data: 'Otherwise normal.', editable: true,
                                source: null
                            },
                        ]
                    },
                    {
                        type: "label",
                        data: "Neuro/Psych:",
canDelete: true,
                        items: [
                            {
                                type: "input",
                                placeholder: "",
                                data: "",
                            },
                            {
                                type: "text",
                                data: 'Mental status as above;',
                                editable: true,
                                source: null
                            },
                            ...components.RenderRadioSingleBLocks(['focal findings']),
                            {
                                type: "text",
                                data: '.',
                                editable: true,
                                source: null
                            },
                        ]
                    },
                ]
            }),
            {
                type: 'label',
                data: 'TREATMENT',
                required: true,
                items: [
                    {
                        type: 'blockCheckboxCondition',
                        extraType: 'treatment',
                        data: {
                            checkbox: [{checked: true, data: '', isHide: true}],
                            hiddenBlock: [
                                {
                                    type: "text",
                                    data: 'Tetanus prophylaxis',
                                    editable: true,
                                    source: null
                                },
                                {
                                    type: "radio-block",
                                    data: [{checked: false, data: "was"}, {checked: false, data: "was not"}]
                                },
                                {
                                    type: "text",
                                    data: 'administered.',
                                    editable: true,
                                    source: null
                                },
                            ]
                        }
                    },
                ]
            },
            components.AssessmentPlan([ {
                type: 'blockCheckboxCondition',
                extraType: 'assessmentContent',
                data: {
                    checkbox: [{checked: true, data: '', isHide: true}],
                    hiddenBlock: [
                        {
                            type: "text",
                            data: 'Follow-up to be arranged by',
                            editable: true,
                            source: null
                        },
                        {
                            type: "radio-block",
                            data: [{checked: true, data: "patient"},
                                {checked: false, data: "caretaker"}]

                        },
                        {
                            type: "text",
                            data: 'with',
                            editable: true,
                            source: null
                        },
                        {
                            type: "radio-block",
                            data: [{checked: true, data: "PCP"},
                                {checked: false, data: "Dermatologist"},
                            ]
                        },
                        {
                            type: "text",
                            data: 'in',
                            editable: true,
                            source: null
                        },
                        {
                            type: 'input-static-text',
                            label: '',
                            data: [{data: '', mask: '999', min: 0, max: 365}],
                            deviders: []
                        },
                        {
                            type: "text",
                            data: 'days for further evaluation. Please go to ED if you develop high fever, increased pain or other symptoms.',
                            editable: true,
                            source: null
                        },
                    ]
                }
            }]),
        ]
    }
}
