import * as components from "./components";
import {PatientData} from "./components/PatientData";

export const template = {
    name: 'Tick bite',
    body: {
        body: [
            {
                type: "label",
                data: "PRESENTING PROBLEM:",
                items: [
                    {
                        type: "text",
                        data: 'Tick bite (bite/sting tick)',
                        editable: true,
                        source: null
                    },
                ]
            },
            {
                type: "label",
                data: "HISTORY OF THE PRESENT ILLNESS:",
                required: true,
                items: [
                    ...PatientData(),
                    {
                        type: "text",
                        data: 'who reports noting tick embedded in',
                        editable: true,
                        source: null
                    },
                    {
                        type: "input",
                        placeholder: "location",
                        data: "",
                    },
                    {
                        type: "text",
                        data: 'today. Otherwise:',
                        editable: true,
                        source: null
                    },
                    {
                        type: "checkbox",
                        data: [
                            {checked: false, data: "rash"},
                            {checked: false, data: "fever"},
                            {checked: false, data: "headache"},
                        ]
                    },
                    {
                        type: "text",
                        data: '.',
                        editable: true,
                        source: null
                    },
                    {
                        type: "radio-block",
                        data: [{checked: false, data: "Has"},
                            {checked: false, data: "No"},
                        ]
                    },
                    {
                        type: "text",
                        data: 'known history of Lyme disease.',
                        editable: true,
                        source: null
                    },
                ]
            },
            components.TetanusStatus(),
            components.ReviewOfSystem(),
            components.PMH(),
            components.GYM(),
            components.FamSocHistory(),
            components.Medications(),
            components.Allergies(),
            components.PhysicianExamination({
                items: [
                    {
                        type: "label",
                        data: "Generalized Appearance:",
canDelete: true,
                        items: [
                            {
                                type: "input",
                                placeholder: "",
                                data: "",
                            },
                            {
                                type: "text",
                                data: 'Alert, cooperative, in',
                                editable: true,
                                source: null
                            },
                            {
                                type: "radio-block",
                                data: [{checked: false, data: "no"},
                                    {checked: false, data: "minimal"},
                                    {checked: false, data: "moderate"},
                                    {checked: false, data: "severe"}
                                ]
                            },
                            {
                                type: "text",
                                data: 'distress.',
                                editable: true,
                                source: null
                            },
                        ]
                    },
                    components.VitalSigns(),
                    {
                        type: "label",
                        data: "Skin:",
canDelete: true,
                        items: [
                            {
                                type: "input",
                                placeholder: "",
                                data: "",
                            },
                            {
                                type: "text",
                                data: 'Warm, dry;',
                                editable: true,
                                source: null
                            },
                            ...components.RenderRadioSingleBLocks(['rash']),
                            {
                                type: "text",
                                data: '. Tick measuring approximately',
                                editable: true,
                                source: null
                            },
                            {
                                type: 'input-static-text',
                                label: '',
                                data: [{data: '', mask: '999', min: 0, max: 365}],
                                deviders: []
                            },
                            {
                                type: "text",
                                data: 'mm noted with mouth parts embedded in skin.',
                                editable: true,
                                source: null
                            },
                            ...components.RenderRadioSingleBLocks(
                                ['local rash', 'cellulitis']),
                            {
                                type: "text",
                                data: '.',
                                editable: true,
                                source: null
                            },
                        ]
                    },
                    {
                        type: "label",
                        data: "Head:",
canDelete: true,
                        items: [
                            {
                                type: "input",
                                placeholder: "",
                                data: "",
                            },
                            ...components.RenderRadioSingleBLocks(['scalp swelling', 'tenderness']),
                            {
                                type: "text",
                                data: '.',
                                editable: true,
                                source: null
                            },
                        ]
                    },
                    components.Eyes(components.RenderRadioSingleBLocks(['scleral icterus'], true)),
                    {
                        type: "label",
                        data: "ENMT:",
canDelete: true,
                        items: [
                            {
                                type: "input",
                                placeholder: "",
                                data: "",
                            },
                            {
                                type: "text",
                                data: 'Pharynx:',
                                editable: true,
                                source: null
                            },
                            {
                                type: "radio-single",
                                data: [{checked: false, data: "erythema"}]
                            },
                            {
                                type: "text",
                                data: '; ',
                                editable: true,
                                source: null
                            },
                            {
                                type: "text",
                                data: 'airway patent:',
                                editable: true,
                                source: null
                            },
                            {
                                type: "radio-single",
                                data: [{checked: false, data: "stridor"}]
                            },
                            {
                                type: "text",
                                data: '; ',
                                editable: true,
                                source: null
                            },
                            {
                                type: "text",
                                data: 'mucous membranes moist.',
                                editable: true,
                                source: null
                            },
                        ]
                    },
                    components.Neck(components.RenderRadioSingleBLocks(['thyromegaly'], true)),
                    components.ChestRespiratory(),
                    components.HeartCardiovascular(),
                    {
                        type: "label",
                        data: "Abdomen/GI:", canDelete: true,
                        items: [
                            {
                                type: "input",
                                placeholder: "",
                                data: "",
                            },
                            {
                                type: "text",
                                data: 'Soft;',
                                editable: true,
                                source: null
                            },
                            ...components.RenderRadioSingleBLocks(
                                ['tenderness', 'guarding',
                                    'rebound', 'palpable masses', 'CVA tenderness']),
                            {
                                type: "text",
                                data: '.',
                                editable: true,
                                source: null
                            },
                        ]
                    },

                    components.Extremities(),
                    {
                        type: "label",
                        data: "Neuro/Psych:",
canDelete: true,
                        items: [
                            {
                                type: "input",
                                placeholder: "",
                                data: "",
                            },
                            {
                                type: "text",
                                data: 'Mental status as above. Cranial nerves grossly intact; strength symmetric.',
                                editable: true,
                                source: null
                            },
                        ]
                    },
                ]
            }),
            {
                type: 'label',
                data: 'TREATMENT',
                required: true,
                items: [
                    {
                        type: 'blockCheckboxCondition',
                        extraType: 'treatment',
                        data: {
                            checkbox: [{checked: true, data: '', isHide: true}],
                            hiddenBlock: [
                                {
                                    type: "text",
                                    data: 'Procedure: Tick removed by me in its entirety',
                                    editable: true,
                                    source: null
                                },
                                {
                                    type: "input",
                                    placeholder: "description",
                                    data: "",
                                },
                                {
                                    type: "text",
                                    data: 'Care taken to avoid compression of body of tick.',
                                    editable: true,
                                    source: null
                                },
                            ]
                        }
                    },
                ]
            },
            components.AssessmentPlan([ {
                type: 'blockCheckboxCondition',
                extraType: 'assessmentContent',
                data: {
                    checkbox: [{checked: true, data: '', isHide: true}],
                    hiddenBlock: [
                        {
                            type: "text",
                            data: 'Advised patient regarding symptoms of tick-borne diseases. Follow up with',
                            editable: true,
                            source: null
                        },
                        {
                            type: "radio-block",
                            data: [{checked: true, data: "PCP"},
                                {checked: false, data: "rheumatologist"},
                                {checked: false, data: "lyme specialist"},
                            ]
                        },
                        {
                            type: "text",
                            data: 'for rash, fever, headache, or other unusual symptoms, occurring within the next several weeks.',
                            editable: true,
                            source: null
                        },

                    ]
                }
            }]),
        ]
    }
}
