import * as components from "./components";
import {PatientData} from "./components/PatientData";

export const template = {
    name: 'Abdominal pain F young',
    body: {
        body: [
            {
                type: "label",
                data: "PRESENTING PROBLEM:",
                items: [
                    {
                        type: "text",
                        data: 'Abdominal pain F young',
                        editable: true,
                        source: null
                    },
                ]
            },
            {
                type: "label",
                data: "HISTORY OF THE PRESENT ILLNESS:",
                required: true,
                items: [
                    ...PatientData(),
                    {
                        type: "text",
                        data: 'who complains of',
                        editable: true,
                        source: null
                    },
                    {
                        type: "radio-block",
                        data: [{checked: false, data: "mild"},
                            {checked: false, data: "moderate"},
                            {checked: false, data: "severe"}]
                    },
                    {
                        type: "text",
                        data: 'abdominal pain described as',
                        editable: true,
                        source: null
                    },

                    {
                        type: "input",
                        placeholder: "location",
                        data: "",
                    },
                    {
                        type: "text",
                        data: ',',
                        editable: true,
                        source: null
                    },
                    {
                        type: "input",
                        placeholder: "duration",
                        data: "",
                    },
                    {
                        type: "text",
                        data: ',',
                        editable: true,
                        source: null
                    },

                    {
                        type: "text",
                        data: 'associated with',
                        editable: true,
                        source: null
                    },
                    {
                        type: "checkbox",
                        data: [
                            {checked: false, data: "nausea"},
                            {checked: false, data: "vomiting"},
                            {checked: false, data: "fever"},
                            {checked: false, data: "vaginal bleeding"},
                            {checked: false, data: "melena"},
                            {checked: false, data: "hematochezia"}
                        ]
                    },
                    {
                        type: "text",
                        data: '.',
                        editable: true,
                        source: null
                    },
                    {
                        type: "radio-block",
                        data: [{checked: false, data: "Has"},
                            {checked: false, data: "has not had"}]
                    },
                    {
                        type: "text",
                        data: 'history of prior abdominal surgery.',
                        editable: true,
                        source: null
                    },
                ]
            },
            {
                type: "label",
                data: "REVIEW OF SYSTEMS:",
                items: [
                    {
                        type: "group-block",
                        canDelete: true,
                        items: [
                            {
                                type: "text",
                                data: 'General:',
                                editable: true,
                                source: null
                            },
                            {
                                type: "radio-single",
                                data: [{checked: false, data: "fever"}]
                            },
                            {
                                type: "text",
                                data: '. ',
                                editable: true,
                                source: null
                            }
                        ]
                    },
                    {
                        type: "group-block",
                        canDelete: true,
                        items: [
                            {
                                type: "text",
                                data: 'HENT:',
                                editable: true,
                                source: null
                            },
                            {
                                type: "radio-single",
                                data: [{checked: false, data: "congestion"}]
                            },
                            {
                                type: "text",
                                data: '. ',
                                editable: true,
                                source: null
                            }
                        ]
                    },
                    {
                        type: "group-block",
                        canDelete: true,
                        items: [
                            {
                                type: "text",
                                data: 'Respiratory:',
                                editable: true,
                                source: null
                            },
                            {
                                type: "radio-single",
                                data: [{checked: false, data: "cough"}]
                            },
                            {
                                type: "text",
                                data: '. ',
                                editable: true,
                                source: null
                            }
                        ]
                    },
                    {
                        type: "group-block",
                        canDelete: true,
                        items: [
                            {
                                type: "text",
                                data: 'Cardiovascular:',
                                editable: true,
                                source: null
                            },
                            {
                                type: "radio-single",
                                data: [{checked: false, data: "chest pain"}]
                            },
                            {
                                type: "text",
                                data: '. ',
                                editable: true,
                                source: null
                            }
                        ]
                    },
                    {
                        type: "group-block",
                        canDelete: true,
                        items: [
                            {
                                type: "text",
                                data: 'GI:',
                                editable: true,
                                source: null
                            },
                            {
                                type: "radio-single",
                                data: [{checked: false, data: "prior abdominal pain"}]
                            },
                            {
                                type: "text",
                                data: '. ',
                                editable: true,
                                source: null
                            }
                        ]
                    },
                    {
                        type: "group-block",
                        canDelete: true,
                        items: [
                            {
                                type: "text",
                                data: 'GU:',
                                editable: true,
                                source: null
                            },
                            {
                                type: "radio-single",
                                data: [{checked: false, data: "urinary complaints"}]
                            },
                            {
                                type: "text",
                                data: '. ',
                                editable: true,
                                source: null
                            }
                        ]
                    },
                    {
                        type: "group-block",
                        canDelete: true,
                        items: [
                            {
                                type: "text",
                                data: 'Musculoskeletal:',
                                editable: true,
                                source: null
                            },
                            {
                                type: "radio-single",
                                data: [{checked: false, data: "other aches or pains"}]
                            },
                            {
                                type: "text",
                                data: '. ',
                                editable: true,
                                source: null
                            }
                        ]
                    },
                    {
                        type: "group-block",
                        canDelete: true,
                        items: [
                            {
                                type: "text",
                                data: 'Endocrine:',
                                editable: true,
                                source: null
                            },
                            {
                                type: "radio-single",
                                data: [{checked: false, data: "generalized weakness"}]
                            },
                            {
                                type: "text",
                                data: '. ',
                                editable: true,
                                source: null
                            }
                        ]
                    },
                    {
                        type: "group-block",
                        canDelete: true,
                        items: [
                            {
                                type: "text",
                                data: 'Neurological:',
                                editable: true,
                                source: null
                            },
                            {
                                type: "radio-single",
                                data: [{checked: false, data: "localized weakness"}]
                            },
                            {
                                type: "text",
                                data: '. ',
                                editable: true,
                                source: null
                            }
                        ]
                    },
                    {
                        type: "group-block",
                        canDelete: true,
                        items: [
                            {
                                type: "text",
                                data: 'Psychiatric:',
                                editable: true,
                                source: null
                            },
                            {
                                type: "radio-single",
                                data: [{checked: false, data: "emotional stress"}]
                            },
                            {
                                type: "text",
                                data: '. ',
                                editable: true,
                                source: null
                            }
                        ]
                    },
                ]
            },
            components.PMH(),
            components.GYM(),
            components.FamSocHistory(),
            components.Medications(),
            components.Allergies(),
            components.PhysicianExamination({
                items: [components.GeneralizedAppearance(),
                    components.VitalSigns(),
                    {
                        type: "label",
                        data: "Skin:",
canDelete: true,
                        items: [
                            {
                                type: "input",
                                placeholder: "",
                                data: "",
                            },
                            {
                                type: "text",
                                data: 'Warm, dry;',
                                editable: true,
                                source: null
                            },
                            {
                                type: "radio-single",
                                data: [{checked: false, data: "cyanosis"}]
                            },
                            {
                                type: "text",
                                data: '.',
                                editable: true,
                                source: null
                            },
                        ]
                    },
                    components.Eyes([{
                        type: "text",
                        data: ',',
                        editable: true,
                        source: null
                    },
                        {
                            type: "radio-single",
                            data: [{checked: false, data: "scleral icterus"}]
                        }]),
                    {
                        type: "label",
                        data: "ENMT:",
canDelete: true,
                        items: [
                            {
                                type: "input",
                                placeholder: "",
                                data: "",
                            },
                            {
                                type: "text",
                                data: 'Mucous membranes moist.',
                                editable: true,
                                source: null
                            },
                        ]
                    },
                    components.Neck(),
                    components.ChestRespiratory(),
                    components.HeartCardiovascular(),
                    {
                        type: "label",
                        data: "Abdomen/GI:", canDelete: true,
                        items: [
                            {
                                type: "input",
                                placeholder: "",
                                data: "",
                            },
                            {
                                type: "radio-single",
                                data: [{checked: false, data: "distention"}]
                            },
                            {
                                type: "text",
                                data: '. Bowel sounds active;',
                                editable: true,
                                source: null
                            },
                            {
                                type: "radio-block",
                                data: [{checked: true, data: "mild"},
                                    {checked: false, data: "moderate"}]
                            },
                            {
                                type: "text",
                                data: 'tenderness in',
                                editable: true,
                                source: null
                            },
                            {
                                type: "input",
                                placeholder: "location",
                                data: "",
                            },
                            {
                                type: "text",
                                data: ',',
                                editable: true,
                                source: null
                            },
                            {
                                type: "radio-single",
                                data: [{checked: false, data: "guarding"}]
                            },
                            {
                                type: "text",
                                data: ',',
                                editable: true,
                                source: null
                            },
                            {
                                type: "radio-single",
                                data: [{checked: false, data: "rebound"}]
                            },
                            {
                                type: "text",
                                data: ',',
                                editable: true,
                                source: null
                            },
                            {
                                type: "radio-single",
                                data: [{checked: false, data: "palpable masses"}]
                            },
                            {
                                type: "text",
                                data: ',',
                                editable: true,
                                source: null
                            },

                            {
                                type: "radio-single",
                                data: [{checked: false, data: "CVA tenderness"}]
                            },
                            {
                                type: "text",
                                data: '.',
                                editable: true,
                                source: null
                            },
                        ]
                    },
                    components.Extremities(),
                    {
                        type: "label",
                        data: "Neuro/Psych:",
canDelete: true,
                        items: [
                            {
                                type: "input",
                                placeholder: "",
                                data: "",
                            },
                            {
                                type: "text",
                                data: 'Mental status as above;',
                                editable: true,
                                source: null
                            },
                            {
                                type: "radio-single",
                                data: [{checked: false, data: "focal findings"}]
                            },
                            {
                                type: "text",
                                data: '.', editable: true,
                                source: null
                            },
                        ]
                    },]
            }),
            components.TestPerformed({items: [
                    {
                        type: 'blockCheckboxCondition',
                        data: {
                            checkbox: [{checked: false, data: 'None'}],
                            hiddenBlock: []
                        }
                    },
                    {
                        type: 'blockCheckboxCondition',
                        data: {
                            checkbox: [{checked: false, data: 'UA -'}],
                            hiddenBlock: [
                                {
                                    type: "selector",
                                    data: {value: 'negative', label: 'Negative'},
                                    multi: false,
                                    label: 'Leukocyte esterase',
                                    options: [{value: 'negative', label: 'Negative'},
                                        {value: 'trace', label: 'Trace'},
                                        {value: '+', label: '+'},
                                        {value: '++', label: '++'},
                                        {value: '+++', label: '+++'}],
                                },
                                {
                                    type: "selector",
                                    data: {value: 'negative', label: 'Negative'},
                                    multi: false,
                                    label: 'Nitrates',
                                    options: [{value: 'negative', label: 'Negative'},
                                        {value: 'positive', label: 'Positive'},],
                                },
                                {
                                    type: "selector",
                                    data: {value: '0.2', label: '0.2'},
                                    multi: false,
                                    label: 'Urobilinogen',
                                    options: [{value: '0.2', label: '0.2'},
                                        {value: '1', label: '1'},
                                        {value: '2', label: '2'},
                                        {value: '4', label: '4'},
                                        {value: '8', label: '8'},
                                        {value: '12', label: '12'},],
                                },
                                {
                                    type: "selector",
                                    data: {value: 'negative', label: 'Negative'},
                                    multi: false,
                                    label: 'Proteins',
                                    options: [{value: 'negative', label: 'Negative'},
                                        {value: 'trace', label: 'Trace'},
                                        {value: '+', label: '+'},
                                        {value: '++', label: '++'},
                                        {value: '+++', label: '+++'},
                                        {value: '++++', label: '++++'},],
                                },
                                {
                                    type: "selector",
                                    data: null,
                                    multi: false,
                                    label: 'pH',
                                    options: [{value: '5.0', label: '5.0'},
                                        {value: '6.0', label: '6.0'},
                                        {value: '6.5', label: '6.5'},
                                        {value: '7.0', label: '7.0'},
                                        {value: '7.5', label: '7.5'},
                                        {value: '8.0', label: '8.0'},
                                        {value: '8.5', label: '8.5'}],
                                },
                                {
                                    type: "selector",
                                    data: {value: 'negative', label: 'Negative'},
                                    multi: false,
                                    label: 'Blood',
                                    options: [{value: 'negative', label: 'Negative'},
                                        {value: 'trace', label: 'Trace'},
                                        {value: '+', label: '+'},
                                        {value: '++', label: '++'},
                                        {value: '+++', label: '+++'}],
                                },
                                {
                                    type: "selector",
                                    data: null,
                                    multi: false,
                                    label: 'SG',
                                    options: [{value: '1.000', label: '1.000'},
                                        {value: '1.005', label: '1.005'},
                                        {value: '1.010', label: '1.010'},
                                        {value: '1.015', label: '1.015'},
                                        {value: '1.020', label: '1.020'},
                                        {value: '1.025', label: '1.025'},
                                        {value: '1.030', label: '1.030'},],
                                },
                                {
                                    type: "selector",
                                    data: {value: 'negative', label: 'Negative'},
                                    multi: false,
                                    label: 'Ketones',
                                    options: [{value: 'negative', label: 'Negative'},
                                        {value: '5', label: '5'},
                                        {value: '15', label: '15'},
                                        {value: '40', label: '40'}],
                                },
                                {
                                    type: "selector",
                                    data: {value: 'negative', label: 'Negative'},
                                    multi: false,
                                    label: 'Bilirubin trace',
                                    options: [{value: 'negative', label: 'Negative'},
                                        {value: 'trace', label: 'Trace'},
                                        {value: '+', label: '+'},
                                        {value: '++', label: '++'},
                                        {value: '+++', label: '+++'}],
                                },
                                {
                                    type: "selector",
                                    data: {value: 'negative', label: 'Negative'},
                                    multi: false,
                                    label: 'Glucose',
                                    options: [{value: 'negative', label: 'Negative'},
                                        {value: '100', label: '100'},
                                        {value: '250', label: '250'},
                                        {value: '500', label: '500'},
                                        {value: '1000', label: '1000'},
                                        {value: '>2000', label: '>2000'}],
                                },
                            ]
                        }
                    },
                    {
                        type: 'blockCheckboxCondition',
                        data: {
                            checkbox: [{checked: false, data: 'bHCG -'}],
                            hiddenBlock: [
                                {
                                    type: "radio-block",
                                    data: [{checked: true, data: "Positive"},
                                        {checked: false, data: "Negative"}]
                                },
                            ]
                        }
                    },
                    {
                        type: 'blockCheckboxCondition',
                        data: {
                            checkbox: [{checked: false, data: 'Hemoccult -'}],
                            hiddenBlock: [
                                {
                                    type: "radio-block",
                                    data: [{checked: true, data: "Positive"}, {checked: false, data: "Negative"},
                                        ]
                                },
                            ]
                        }
                    },
                    {
                        type: 'blockCheckboxCondition',
                        data: {
                            checkbox: [{checked: false, data: ''}],
                            hiddenBlock: [
                                {
                                    type: "input",
                                    placeholder: "",
                                    data: "",
                                },
                            ]
                        }
                    },
                ]}),
            components.AssessmentPlan([ {
                type: 'blockCheckboxCondition',
                extraType: 'assessmentContent',
                data: {
                    checkbox: [{checked: true, data: '', isHide: true}],
                    hiddenBlock: [
                        {
                            type: "text",
                            data: 'Go to the nearest ED if pain recurs or change in symptoms.',
                            editable: true,
                            source: null
                        },
                        {
                            type: "text",
                            data: 'Follow-up to be arranged by',
                            editable: true,
                            source: null
                        },
                        {
                            type: "radio-block",
                            data: [{checked: true, data: "patient"},
                                {checked: false, data: "caretaker"},
                                {checked: false, data: "me"},]

                        },
                        {
                            type: "text",
                            data: 'with',
                            editable: true,
                            source: null
                        },
                        {
                            type: "radio-block",
                            data: [{checked: true, data: "PCP"},
                                {checked: false, data: "GI"},
                            ]
                        },
                        {
                            type: "text",
                            data: 'specialist in',
                            editable: true,
                            source: null
                        },
                        {
                            type: 'input-static-text',
                            label: '',
                            data: [{data: '', mask: '999', min: 0, max: 365}],
                            deviders: []
                        },
                        {
                            type: "text",
                            data: 'days for further evaluation.',
                            editable: true,
                            source: null
                        },
                    ]
                }
            }]),
        ]
    }
}
