import * as components from "./components";
import {PatientData} from "./components/PatientData";

export const template = {
    name: 'Asthma (child)',
    body: {
        body: [
            {
                type: "label",
                data: "PRESENTING PROBLEM:",
                items: [
                    {
                        type: "text",
                        data: 'Shortness of breath (asthma kids)',
                        editable: true,
                        source: null
                    },
                ]
            },
            {
                type: "label",
                data: "HISTORY OF THE PRESENT ILLNESS:",
                required: true,
                items: [
                    ...PatientData(),
                    {
                        type: "text",
                        data: 'whose caretaker reports that child has had',
                        editable: true,
                        source: null
                    },
                    {
                        type: "radio-block",
                        data: [{checked: false, data: "mild"},
                            {checked: false, data: "moderate"},
                            {checked: false, data: "severe"}]
                    },
                    {
                        type: "text",
                        data: 'wheezing',
                        editable: true,
                        source: null
                    },
                    {
                        type: "input",
                        placeholder: "duration",
                        data: "",
                    },
                    {
                        type: "text",
                        data: ', despite treatment with',
                        editable: true,
                        source: null
                    },
                    {
                        type: "input",
                        placeholder: "medication name",
                        data: "",
                    },{
                        type: "text",
                        data: '. Otherwise:',
                        editable: true,
                        source: null
                    },
                    {
                        type: "checkbox",
                        data: [
                            {checked: false, data: "fever"},
                            {checked: false, data: "altered mental status"},
                        ]
                    },
                    {
                        type: "text",
                        data: '.',
                        editable: true,
                        source: null
                    },
                ]
            },
            {
                type: "label",
                data: "REVIEW OF SYSTEMS:",
                items: [
                    {
                        type: "group-block",
                        canDelete: true,
                        items: [
                            {
                                type: "text",
                                data: 'General:',
                                editable: true,
                                source: null
                            },
                            {
                                type: "radio-single",
                                data: [{checked: false, data: "prior fever"}]
                            },
                            {
                                type: "text",
                                data: '. ',
                                editable: true,
                                source: null
                            }
                        ]
                    },
                    {
                        type: "group-block",
                        canDelete: true,
                        items: [
                            {
                                type: "text",
                                data: 'Skin:',
                                editable: true,
                                source: null
                            },
                            {
                                type: "radio-single",
                                data: [{checked: false, data: "rash"}]
                            },
                            {
                                type: "text",
                                data: '. ',
                                editable: true,
                                source: null
                            }
                        ]
                    },
                    {
                        type: "group-block",
                        canDelete: true,
                        items: [
                            {
                                type: "text",
                                data: 'Eyes:',
                                editable: true,
                                source: null
                            },
                            {
                                type: "radio-single",
                                data: [{checked: false, data: "discharge"}]
                            },
                            {
                                type: "text",
                                data: '. ',
                                editable: true,
                                source: null
                            }
                        ]
                    },
                    {
                        type: "group-block",
                        canDelete: true,
                        items: [
                            {
                                type: "text",
                                data: 'HENT:',
                                editable: true,
                                source: null
                            },
                            {
                                type: "radio-single",
                                data: [{checked: false, data: "prior congestion"}]
                            },
                            {
                                type: "text",
                                data: '. ',
                                editable: true,
                                source: null
                            }
                        ]
                    },
                    {
                        type: "group-block",
                        canDelete: true,
                        items: [
                            {
                                type: "text",
                                data: 'Respiratory:',
                                editable: true,
                                source: null
                            },
                            {
                                type: "radio-single",
                                data: [{checked: false, data: "prior cough"}]
                            },
                            {
                                type: "text",
                                data: '. ',
                                editable: true,
                                source: null
                            }
                        ]
                    },
                    {
                        type: "group-block",
                        canDelete: true,
                        items: [
                            {
                                type: "text",
                                data: 'Cardiovascular:',
                                editable: true,
                                source: null
                            },
                            {
                                type: "radio-single",
                                data: [{checked: false, data: "chest pain"}]
                            },
                            {
                                type: "text",
                                data: '. ',
                                editable: true,
                                source: null
                            }
                        ]
                    },
                    {
                        type: "group-block",
                        canDelete: true,
                        items: [
                            {
                                type: "text",
                                data: 'GI:',
                                editable: true,
                                source: null
                            },
                            {
                                type: "radio-single",
                                data: [{checked: false, data: "vomiting"}]
                            },
                            {
                                type: "text",
                                data: '. ',
                                editable: true,
                                source: null
                            }
                        ]
                    },
                    {
                        type: "group-block",
                        canDelete: true,
                        items: [
                            {
                                type: "text",
                                data: 'GU:',
                                editable: true,
                                source: null
                            },
                            {
                                type: "radio-single",
                                data: [{checked: false, data: "urinary complaints"}]
                            },
                            {
                                type: "text",
                                data: '. ',
                                editable: true,
                                source: null
                            }
                        ]
                    },
                    {
                        type: "group-block",
                        canDelete: true,
                        items: [
                            {
                                type: "text",
                                data: 'Musculoskeletal:',
                                editable: true,
                                source: null
                            },
                            {
                                type: "radio-single",
                                data: [{checked: false, data: "joint swelling"}]
                            },
                            {
                                type: "text",
                                data: '. ',
                                editable: true,
                                source: null
                            }
                        ]
                    },
                    {
                        type: "group-block",
                        canDelete: true,
                        items: [
                            {
                                type: "text",
                                data: 'Psychiatric:',
                                editable: true,
                                source: null
                            },
                            {
                                type: "radio-single",
                                data: [{checked: false, data: "emotional stress"}]
                            },
                            {
                                type: "text",
                                data: '. ',
                                editable: true,
                                source: null
                            }
                        ]
                    },
                ]
            },
            components.PMH(),
            components.FamSocHistory(),
            components.Medications(),
            components.Allergies(),
            components.PhysicianExamination({
                items: [
                    components.GeneralizedAppearance(),
                    components.VitalSigns(),
                    {
                        type: "label",
                        data: "Skin:",
canDelete: true,
                        items: [
                            {
                                type: "input",
                                placeholder: "",
                                data: "",
                            },
                            {
                                type: "text",
                                data: 'Warm, dry;',
                                editable: true,
                                source: null
                            },
                            {
                                type: "radio-single",
                                data: [{checked: false, data: "cyanosis"}]
                            },
                            {
                                type: "text",
                                data: ';',
                                editable: true,
                                source: null
                            },
                            {
                                type: "radio-single",
                                data: [{checked: false, data: "decreased turgor"}]
                            },
                            {
                                type: "text",
                                data: ';',
                                editable: true,
                                source: null
                            },{
                                type: "radio-single",
                                data: [{checked: false, data: "rash"}]
                            },
                            {
                                type: "text",
                                data: '.',
                                editable: true,
                                source: null
                            },
                        ]
                    },
                    components.Eyes(),
                    {
                        type: "label",
                        data: "ENMT:",
canDelete: true,
                        items: [
                            {
                                type: "input",
                                placeholder: "",
                                data: "",
                            },
                            {
                                type: "text",
                                data: 'TMs',
                                editable: true,
                                source: null
                            },
                            {
                                type: "radio-single",
                                data: [{checked: false, data: "erythema"}]
                            },
                            {
                                type: "text",
                                data: '. Pharynx:',
                                editable: true,
                                source: null
                            },
                            {
                                type: "radio-single",
                                data: [{checked: false, data: "tonsillar erythema"}]
                            },
                            {
                                type: "text",
                                data: ',',
                                editable: true,
                                source: null
                            },
                            {
                                type: "radio-single",
                                data: [{checked: false, data: "exudate"}]
                            },
                            {
                                type: "text",
                                data: '. Airway patent,',
                                editable: true,
                                source: null
                            },
                            {
                                type: "radio-single",
                                data: [{checked: false, data: "stridor"}]
                            },
                            {
                                type: "text",
                                data: '. Mucous membranes moist.',
                                editable: true,
                                source: null
                            },
                        ]
                    },
                    {
                        type: "label",
                        data: "Neck:",
canDelete: true,
                        items: [
                            {
                                type: "input",
                                placeholder: "",
                                data: "",
                            },
                            ...components.RenderRadioSingleBLocks(
                                ['stiffness',
                                    'meningismus',
                                    'lymphadenopathy'
                                ]),
                            {
                                type: "text",
                                data: '.',
                                editable: true,
                                source: null
                            },
                        ]
                    },
                    {
                        type: "label",
                        data: "Chest/Respiratory:",
canDelete: true,
                        required: true,
                        items: [{
                            type: "input",
                            placeholder: "",
                            data: "",
                        },
                            {
                                type: "radio-single",
                                data: [{checked: true, data: "retractions"}]
                            },
                            {
                                type: "text",
                                data: ',',
                                editable: true,
                                source: null
                            },
                            {
                                type: "radio-single",
                                data: [{checked: true, data: "bilateral expiratory wheezing"}]
                            },
                            {
                                type: "text",
                                data: ',',
                                editable: true,
                                source: null
                            },
                            {
                                type: "radio-single",
                                data: [{checked: false, data: "rales"}]
                            },
                            {
                                type: "text",
                                data: ',',
                                editable: true,
                                source: null
                            },
                            {
                                type: "radio-single",
                                data: [{checked: false, data: "rhonchi"}]
                            },
                            {
                                type: "text",
                                data: '; breath sounds equal bilaterally.',
                                editable: true,
                                source: null
                            },]
                    },
                    components.HeartCardiovascular(),
                    {
                        type: "label",
                        data: "Abdomen/GI:", canDelete: true,
                        items: [
                            {
                                type: "input",
                                placeholder: "",
                                data: "",
                            },
                            {
                                type: "text",
                                data: 'Soft;',
                                editable: true,
                                source: null
                            },
                            {
                                type: "radio-single",
                                data: [{checked: false, data: "tenderness"}]
                            },
                            {
                                type: "text",
                                data: '.',
                                editable: true,
                                source: null
                            },
                        ]
                    },
                    {
                        type: "label",
                        data: "Extremities:",
canDelete: true,
                        items: [
                            {
                                type: "input",
                                placeholder: "",
                                data: "",
                            },
                            {
                                type: "radio-single",
                                data: [{checked: false, data: "deformity"}]
                            },
                            {
                                type: "text",
                                data: '.', editable: true,
                                source: null
                            },
                        ]
                    },
                    {
                        type: "label",
                        data: "Neuro/Psych:",
canDelete: true,
                        items: [
                            {
                                type: "input",
                                placeholder: "",
                                data: "",
                            },
                            {
                                type: "text",
                                data: 'Mental status as above; interacts appropriately for age. Strength and tone good.',
                                editable: true,
                                source: null
                            },
                        ]
                    },
                ]
            }),
            components.TestPerformed({items: [
                    {
                        type: 'blockCheckboxCondition',
                        data: {
                            checkbox: [{checked: false, data: 'Covid 19 -'}],
                            hiddenBlock: [
                                {
                                    type: "radio-block",
                                    data: [{checked: true, data: "Positive"}
                                        ,{checked: false, data: "Negative"},
                                        ]
                                },
                            ]
                        }
                    },
                    {
                        type: 'blockCheckboxCondition',
                        data: {
                            checkbox: [{checked: false, data: 'Flu -'}],
                            hiddenBlock: [
                                {
                                    type: "radio-block",
                                    data: [{checked: true, data: "Positive"},
                                        {checked: false, data: "Negative"},
                                        ]
                                },
                            ]
                        }
                    },
                ]}),
            components.TreatmentPerformed({items: [{
                    type: "text",
                    data: 'Patient remained stable throughout evaluation and treatment. Treated with Solumedrol 125mg IM',
                    editable: true,
                    source: null
                }]}),
            components.AssessmentPlan([ {
                type: 'blockCheckboxCondition',
                extraType: 'assessmentContent',
                data: {
                    checkbox: [{checked: true, data: '', isHide: true}],
                    hiddenBlock: [
                        {
                            type: "text",
                            data: 'Follow up consultation to be arranged by caretaker with',
                            editable: true,
                            source: null
                        },
                        {
                            type: "radio-block",
                            data: [{checked: true, data: "PCP"},
                                {checked: false, data: "Allergist"},
                                {checked: false, data: "Pulmonologist"},]
                        },
                        {
                            type: "text",
                            data: 'in',
                            editable: true,
                            source: null
                        },
                        {
                            type: 'input-static-text',
                            label: '',
                            data: [{data: '', mask: '999', min: 0, max: 365}],
                            deviders: []
                        },
                        {
                            type: "text",
                            data: 'days for further evaluation. Go to the nearest ED for increasing difficulty breathing or wheezing.',
                            editable: true,
                            source: null
                        }
                    ]
                }
            }], 'Caretaker received verbal instructions regarding this condition. Written instructions will be send to the parent following the visit.'),
        ]
    }
}
