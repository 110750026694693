import * as components from "./components";
import {PatientData} from "./components/PatientData";
import {GroupBlockReviewOfSystem} from "./components/GroupBlockReviewOfSystems";

export const template = {
    name: 'Back pain (strain)',
    body: {
        body: [
            {
                type: "label",
                data: "PRESENTING PROBLEM:",
                items: [
                    {
                        type: "text",
                        data: 'Back pain (back strain/pain)',
                        editable: true,
                        source: null
                    },
                ]
            },
            {
                type: "label",
                data: "HISTORY OF THE PRESENT ILLNESS:",
                required: true,
                items: [
                    ...PatientData(),
                    {
                        type: "text",
                        data: 'whose reports',
                        editable: true,
                        source: null
                    },
                    {
                        type: "input",
                        placeholder: "quality",
                        data: "",
                    },
                    {
                        type: "text",
                        data: ',',
                        editable: true,
                        source: null
                    },
                    {
                        type: "radio-block",
                        data: [{checked: false, data: "mild"},
                            {checked: false, data: "mod"},
                            {checked: false, data: "severe"}]
                    },
                    {
                        type: "text",
                        data: 'pain in the lower back, beginning',
                        editable: true,
                        source: null
                    },
                    {
                        type: "input",
                        placeholder: "duration",
                        data: "",
                    }, {
                        type: "text",
                        data: '. Otherwise:',
                        editable: true,
                        source: null
                    },
                    {
                        type: "checkbox",
                        data: [
                            {checked: false, data: "paresthesias"},
                            {checked: false, data: "weakness"},
                            {checked: false, data: "urinary incontinence"},
                        ]
                    },
                    {
                        type: "text",
                        data: '.',
                        editable: true,
                        source: null
                    },
                    {
                        type: "radio-block",
                        data: [{checked: false, data: "Has"},
                            {checked: false, data: "Has no"}]
                    },
                    {
                        type: "text",
                        data: 'history of prior back problem.',
                        editable: true,
                        source: null
                    }
                ]
            },
            {
                type: "label",
                data: "REVIEW OF SYSTEMS:",
                items: [
                    {
                        type: "text",
                        data: 'Other than the symptoms associated with the present events, the following is reported with regard to recent health:',
                        editable: true,
                        source: null
                    },
                    {
                        type: "group-block",
                        canDelete: true,
                        items: [
                            {
                                type: "text",
                                data: 'General:',
                                editable: true,
                                source: null
                            },
                            {
                                type: "radio-single",
                                data: [{checked: false, data: "fever"}]
                            },
                            {
                                type: "text",
                                data: '. ',
                                editable: true,
                                source: null
                            }
                        ]
                    },
                    {
                        type: "group-block",
                        canDelete: true,
                        items: [
                            {
                                type: "text",
                                data: 'HENT:',
                                editable: true,
                                source: null
                            },
                            {
                                type: "radio-single",
                                data: [{checked: false, data: "congestion"}]
                            },
                            {
                                type: "text",
                                data: '. ',
                                editable: true,
                                source: null
                            }
                        ]
                    },
                    {
                        type: "group-block",
                        canDelete: true,
                        items: [
                            {
                                type: "text",
                                data: 'Respiratory:',
                                editable: true,
                                source: null
                            },
                            {
                                type: "radio-single",
                                data: [{checked: false, data: "cough"}]
                            },
                            {
                                type: "text",
                                data: '. ',
                                editable: true,
                                source: null
                            }
                        ]
                    },
                    {
                        type: "group-block",
                        canDelete: true,
                        items: [
                            {
                                type: "text",
                                data: 'Cardiovascular:',
                                editable: true,
                                source: null
                            },
                            {
                                type: "radio-single",
                                data: [{checked: false, data: "chest pain"}]
                            },
                            {
                                type: "text",
                                data: '. ',
                                editable: true,
                                source: null
                            }
                        ]
                    },
                    {
                        type: "group-block",
                        canDelete: true,
                        items: [
                            {
                                type: "text",
                                data: 'GI:',
                                editable: true,
                                source: null
                            },
                            {
                                type: "radio-single",
                                data: [{checked: false, data: "abdominal pain"}]
                            },
                            {
                                type: "text",
                                data: '. ',
                                editable: true,
                                source: null
                            }
                        ]
                    },
                    GroupBlockReviewOfSystem({title: 'GU:', radio: 'urinary complaints'}),
                    GroupBlockReviewOfSystem({title: 'Musculoskeletal:', radio: 'other aches or pains'}),
                    GroupBlockReviewOfSystem({title: 'Endocrine:', radio: 'generalized weakness'}),
                    GroupBlockReviewOfSystem({title: 'Neurological:', radio: 'localized weakness'}),
                    GroupBlockReviewOfSystem({title: 'Psychiatric:', radio: 'emotional stress'}),
                ]
            },
            components.PMH(),
            components.GYM(),
            components.FamSocHistory(),
            components.Medications(),
            components.Allergies(),
            components.PhysicianExamination({
                items: [
                    components.GeneralizedAppearance(),
                    components.VitalSigns(),
                    {
                        type: "label",
                        data: "Skin:",
canDelete: true,
                        items: [
                            {
                                type: "input",
                                placeholder: "",
                                data: "",
                            },
                            {
                                type: "text",
                                data: 'Warm, dry;',
                                editable: true,
                                source: null
                            },
                            {
                                type: "radio-single",
                                data: [{checked: false, data: "cyanosis"}]
                            },
                            {
                                type: "text",
                                data: '.',
                                editable: true,
                                source: null
                            },
                        ]
                    },
                    components.Eyes(),
                    {
                        type: "label",
                        data: "ENMT:",
canDelete: true,
                        items: [
                            {
                                type: "input",
                                placeholder: "",
                                data: "",
                            },
                            {
                                type: "text",
                                data: 'Mucous membranes moist.',
                                editable: true,
                                source: null
                            },
                        ]
                    },
                    components.Neck(),
                    {
                        type: "label",
                        data: "Chest/Respiratory:",
canDelete: true,
                        required: true,
                        items: [
                            {
                                type: "input",
                                placeholder: "",
                                data: "",
                            },
                            {
                                type: "radio-single",
                                data: [{checked: false, data: "rales"}]
                            },
                            {
                                type: "radio-single",
                                data: [{checked: false, data: "rhonchi"}]
                            },
                            {
                                type: "radio-single",
                                data: [{checked: false, data: "wheezes"}]
                            },
                            {
                                type: "text",
                                data: '; breath sounds equal bilaterally.',
                                editable: true,
                                source: null
                            },
                        ]
                    },
                    components.HeartCardiovascular(),
                    {
                        type: "label",
                        data: "Abdomen/GI:", canDelete: true,
                        items: [
                            {
                                type: "input",
                                placeholder: "",
                                data: "",
                            },
                            {
                                type: "text",
                                data: 'Soft;',
                                editable: true,
                                source: null
                            },
                            {
                                type: "radio-single",
                                data: [{checked: false, data: "tenderness"}]
                            },
                            {
                                type: "text",
                                data: ';',
                                editable: true,
                                source: null
                            },
                            {
                                type: "radio-single",
                                data: [{checked: false, data: "palpable mass."}]
                            },
                            {
                                type: "text",
                                data: '.',
                                editable: true,
                                source: null
                            },
                        ]
                    },
                    {
                        type: "label",
                        data: "Back:",
canDelete: true,
                        items: [
                            {
                                type: "input",
                                placeholder: "",
                                data: "",
                            },
                            {
                                type: "text",
                                data: 'Diffusely tender paralumbar area,', editable: true,
                                source: null
                            },
                            {
                                type: "radio-single",
                                data: [{checked: true, data: "mild spasm"}]
                            },
                            {
                                type: "text",
                                data: ',', editable: true,
                                source: null
                            },
                            {
                                type: "radio-single",
                                data: [{checked: false, data: "direct bony tenderness"}]
                            },
                            {
                                type: "text",
                                data: ',', editable: true,
                                source: null
                            },
                            {
                                type: "radio-single",
                                data: [{checked: false, data: "deformity"}]
                            },
                            {
                                type: "text",
                                data: '. Straight leg raising', editable: true,
                                source: null
                            },
                            {
                                type: "radio-single",
                                data: [{checked: false, data: "bilaterally"}]
                            },
                            {
                                type: "text",
                                data: '.', editable: true,
                                source: null
                            },
                        ]
                    },
                    {
                        type: "label",
                        data: "Extremities:",
canDelete: true,
                        items: [
                            {
                                type: "input",
                                placeholder: "",
                                data: "",
                            },
                            {
                                type: "radio-single",
                                data: [{checked: false, data: "deformity"}]
                            },
                            {
                                type: "text",
                                data: '. Distal pulses good bilaterally.', editable: true,
                                source: null
                            },
                        ]
                    },
                    {
                        type: "label",
                        data: "Neuro/Psych:",
canDelete: true,
                        items: [
                            {
                                type: "input",
                                placeholder: "",
                                data: "",
                            },
                            {
                                type: "text",
                                data: 'Intact sensation bilaterally; normal strength in extension of the knees, plantar and dorsiflexion of the toes. DTRs symmetric.',
                                editable: true,
                                source: null
                            },
                        ]
                    },
                ]
            }),
            components.TestPerformed({items: [
                    {
                        type: 'blockCheckboxCondition',
                        data: {
                            checkbox: [{checked: false, data: 'None'}],
                            hiddenBlock: []
                        }
                    },
                    {
                        type: 'blockCheckboxCondition',
                        data: {
                            checkbox: [{checked: false, data: 'Covid 19 -'}],
                            hiddenBlock: [
                                {
                                    type: "radio-block",
                                    data: [{checked: true, data: "Positive"}, {checked: false, data: "Negative"},
                                        ]
                                },
                            ]
                        }
                    },
                    {
                        type: 'blockCheckboxCondition',
                        data: {
                            checkbox: [{checked: false, data: 'UA -'}],
                            hiddenBlock: [
                                {
                                    type: "selector",
                                    data: {value: 'negative', label: 'Negative'},
                                    multi: false,
                                    label: 'Leukocyte esterase',
                                    options: [{value: 'negative', label: 'Negative'},
                                        {value: 'trace', label: 'Trace'},
                                        {value: '+', label: '+'},
                                        {value: '++', label: '++'},
                                        {value: '+++', label: '+++'}],
                                },
                                {
                                    type: "selector",
                                    data: {value: 'negative', label: 'Negative'},
                                    multi: false,
                                    label: 'Nitrates',
                                    options: [{value: 'negative', label: 'Negative'},
                                        {value: 'positive', label: 'Positive'},],
                                },
                                {
                                    type: "selector",
                                    data: {value: '0.2', label: '0.2'},
                                    multi: false,
                                    label: 'Urobilinogen',
                                    options: [{value: '0.2', label: '0.2'},
                                        {value: '1', label: '1'},
                                        {value: '2', label: '2'},
                                        {value: '4', label: '4'},
                                        {value: '8', label: '8'},
                                        {value: '12', label: '12'},],
                                },
                                {
                                    type: "selector",
                                    data: {value: 'negative', label: 'Negative'},
                                    multi: false,
                                    label: 'Proteins',
                                    options: [{value: 'negative', label: 'Negative'},
                                        {value: 'trace', label: 'Trace'},
                                        {value: '+', label: '+'},
                                        {value: '++', label: '++'},
                                        {value: '+++', label: '+++'},
                                        {value: '++++', label: '++++'},],
                                },
                                {
                                    type: "selector",
                                    data: null,
                                    multi: false,
                                    label: 'pH 0.0',
                                    options: [{value: '5.0', label: '5.0'},
                                        {value: '6.0', label: '6.0'},
                                        {value: '6.5', label: '6.5'},
                                        {value: '7.0', label: '7.0'},
                                        {value: '7.5', label: '7.5'},
                                        {value: '8.0', label: '8.0'},
                                        {value: '8.5', label: '8.5'}],
                                },
                                {
                                    type: "selector",
                                    data: {value: 'negative', label: 'Negative'},
                                    multi: false,
                                    label: 'Blood',
                                    options: [{value: 'negative', label: 'Negative'},
                                        {value: 'trace', label: 'Trace'},
                                        {value: '+', label: '+'},
                                        {value: '++', label: '++'},
                                        {value: '+++', label: '+++'}],
                                },
                                {
                                    type: "selector",
                                    data: null,
                                    multi: false,
                                    label: 'SG 1.000',
                                    options: [{value: '1.000', label: '1.000'},
                                        {value: '1.005', label: '1.005'},
                                        {value: '1.010', label: '1.010'},
                                        {value: '1.015', label: '1.015'},
                                        {value: '1.020', label: '1.020'},
                                        {value: '1.025', label: '1.025'},
                                        {value: '1.030', label: '1.030'},],
                                },
                                {
                                    type: "selector",
                                    data: {value: 'negative', label: 'Negative'},
                                    multi: false,
                                    label: 'Ketones',
                                    options: [{value: 'negative', label: 'Negative'},
                                        {value: '5', label: '5'},
                                        {value: '15', label: '15'},
                                        {value: '40', label: '40'}],
                                },
                                {
                                    type: "selector",
                                    data: {value: 'negative', label: 'Negative'},
                                    multi: false,
                                    label: 'Bilirubin trace',
                                    options: [{value: 'negative', label: 'Negative'},
                                        {value: 'trace', label: 'Trace'},
                                        {value: '+', label: '+'},
                                        {value: '++', label: '++'},
                                        {value: '+++', label: '+++'}],
                                },
                                {
                                    type: "selector",
                                    data: {value: 'negative', label: 'Negative'},
                                    multi: false,
                                    label: 'Glucose',
                                    options: [{value: 'negative', label: 'Negative'},
                                        {value: '100', label: '100'},
                                        {value: '250', label: '250'},
                                        {value: '500', label: '500'},
                                        {value: '1000', label: '1000'},
                                        {value: '>2000', label: '>2000'}],
                                },
                            ]
                        }
                    },
                    {
                        type: 'blockCheckboxCondition',
                        data: {
                            checkbox: [{checked: false, data: ''}],
                            hiddenBlock: [
                                {
                                    type: "input",
                                    placeholder: "",
                                    data: "",
                                },
                            ]
                        }
                    },
                ]}),
            components.TreatmentPerformed({items: [{
                    type: "text",
                    data: 'Toradol 60mg IM.',
                    editable: true,
                    source: null
                }]}),
            components.AssessmentPlan([ {
                type: 'blockCheckboxCondition',
                extraType: 'assessmentContent',
                data: {
                    checkbox: [{checked: true, data: '', isHide: true}],
                    hiddenBlock: [
                        {
                            type: "text",
                            data: 'Follow up consultation to be arranged by',
                            editable: true,
                            source: null
                        },
                        {
                            type: "radio-block",
                            data: [{checked: true, data: "patient"},
                                {checked: false, data: "caretaker"},
                                {checked: false, data: "me"}]
                        },
                        {
                            type: "text",
                            data: 'with',
                            editable: true,
                            source: null
                        },
                        {
                            type: "radio-block",
                            data: [{checked: true, data: "PCP"},
                                {checked: false, data: "Orthopedic surgeon"}]
                        },
                        {
                            type: "text",
                            data: 'in',
                            editable: true,
                            source: null
                        },
                        {
                            type: 'input-static-text',
                            label: '',
                            data: [{data: '', mask: '999', min: 0, max: 365}],
                            deviders: []
                        },
                        {
                            type: "text",
                            data: 'days for further evaluation. Go to the nearest ED in case of weakness, numbness, incontinence, increased pain or other symptoms occur.',
                            editable: true,
                            source: null
                        },
                    ]
                }
            }]),
        ]
    }
}
