import logo from "../../../../../dist/images/common/logo.svg";
import {Typography} from "../../../../Common/Typography/Typography";
import React from "react";

import './style.scss'
import moment from "moment";

export const HeaderNotes = ({contextComponent, date, dataRequest}) => {
    const doctorInitial = dataRequest?.doctor_data?.first_name + ' ' + dataRequest?.doctor_data?.last_name;
    const degree = dataRequest?.doctor_data?.title;
    return (
        <>
            <div className='header-img'>
                <img src={logo}/>
            </div>
            <Typography text={contextComponent.title} className='header-title'/>
            <div className='line'/>
            <div>{date}</div>
            <div className="header__doctor-info">
                <div className='doctor-info'>
                    <p>Service Provider: PBDHC (Tax ID: 30-1248445)</p>
                    <p>Physician Provider: {`${doctorInitial}${degree ? `, ${degree}` : ''}`} (NPI {dataRequest?.doctor_data?.npi})</p>
                </div>
                <div className="doctor-address">
                    2001 Palm Beach Lakes Blvd
                    <br/>
                    Suite 502-L
                    <br/>
                    West Palm Beach, FL 33409
                    <br/>
                    Phone: (561) 599-8642
                    <br/>
                    Fax: (706) 397-6204
                    <br/>
                    Email: <a href="mailto:contactus@pbdhc.com" className='doctor-email'>contactus@pbdhc.com</a>
                </div>
            </div>
        </>
    )
}
